<template>
  <b-container>
    <b-row>
      <b-col md="4">
        <b-card>
          <b-input-group>
            <b-form-input v-model="newMark.name" placeholder="Название марки" />
            <b-input-group-append>
              <b-button variant="primary" @click="createMark"
                >Добавить</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-card>
        <b-card>
          <b-list-group>
            <template v-for="car in marks">
              <b-list-group-item
                :key="car.id"
                :active="selectedMark.id === car.id"
                @click="selectedMark = car"
              >
                {{ car.name }}
              </b-list-group-item>
            </template>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col md="4">
        <template v-if="selectedMark.id">
          <b-card>
            <b-input-group>
              <b-form-input
                v-model="newModel.name"
                placeholder="Название модели"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="createModel"
                  >Добавить</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-card>
          <b-card>
            <b-list-group>
              <template v-for="model in models">
                <b-list-group-item
                  :key="model.id"
                  :active="selectedModel.id === model.id"
                  @click="selectedModel = model"
                >
                  {{ model.name }}
                </b-list-group-item>
              </template>
            </b-list-group>
          </b-card>
        </template>
        <p v-else>Не выбрана модель или нет моделей в списке</p>
      </b-col>
      <b-col md="4">
        <b-card>
          <b-form v-if="selectedMark.id">
            <b-form-group label="Название модели">
              <b-form-input v-model="selectedMark.name" />
            </b-form-group>
            <div class="d-flex">
              <b-button variant="success" size="sm" @click="updateMark"
                >Обновить</b-button
              >
              <b-button
                variant="danger"
                size="sm"
                class="ml-1"
                @click="deleteMark"
                >Удалить</b-button
              >
            </div>
          </b-form>
          <p v-else>Выберите марку для изменения</p>
          <b-form v-if="selectedModel.id" class="mt-2">
            <b-form-group label="Название модели">
              <b-form-input v-model="selectedModel.name" />
            </b-form-group>
            <div class="d-flex">
              <b-button variant="success" size="sm" @click="updateModel"
                >Обновить</b-button
              >
              <b-button
                variant="danger"
                size="sm"
                class="ml-1"
                @click="deleteModel"
                >Удалить</b-button
              >
            </div>
          </b-form>
          <p v-else class="mt-2">Выберите модель для изменения</p>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'CarList',
  data() {
    return {
      marks: [],
      models: [],
      selectedMark: {},
      selectedModel: {},
      newModel: {},
      newMark: {},
      loading: false,
    }
  },
  watch: {
    selectedMark: {
      deep: true,
      handler() {
        this.newModel.manufacturer = this.selectedMark.id
        this.getModels()
      },
    },
  },
  created() {
    this.getMarks()
  },
  methods: {
    async getMarks() {
      this.loading = true
      const res = await this.$http.get('admin/car-manufacturers/')
      this.marks = res.data.results
      this.loading = false
    },
    async getModels() {
      this.loading = true
      const res = await this.$http.get(
        `admin/car-models/?manufacturer=${this.selectedMark.id}`
      )
      this.models = res.data.results
      this.loading = false
    },
    async updateMark() {
      await this.$http.patch(
        `admin/car-manufacturers/${this.selectedMark.id}/`,
        this.selectedMark
      )
      await this.getMarks()
    },
    async deleteMark() {
      await this.$http.delete(
        `admin/car-manufacturers/${this.selectedMark.id}/`
      )
      await this.getMarks()
    },
    async updateModel() {
      await this.$http.patch(
        `admin/car-models/${this.selectedModel.id}/`,
        this.selectedModel
      )
      await this.getModels()
    },
    async deleteModel() {
      await this.$http.patch(`admin/car-models/${this.selectedModel.id}/`)
      await this.getModels()
    },
    async createMark() {
      await this.$http.post('admin/car-manufacturers/', this.newMark)
      this.newMark.name = ''
      await this.getMarks()
    },
    async createModel() {
      await this.$http.post('admin/car-models/', this.newModel)
      this.newModel.name = ''
      await this.getModels()
    },
  },
}
</script>
